import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { Nav, Navbar, Container } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "../css/theme-daucodesigns.scss"

import LogoDaucodesigns from "../svg/daucodesigns-site.svg"

function MainNavbar() {

  const [containerStyle, setContainerStyle] = useState({ alignItems: "flex-start"})

  useEffect(() => {
    if (typeof window !== "undefined") {
      if(window.screen.width<977){
        setContainerStyle({alignItems: "flex-start", padding:0})
      }
    }
  }, [])

  const location = useLocation()
  const path = location.pathname

  return (
    <>
      <div>
        <Navbar bg="dark" expand="lg" fixed="top" id="navbar">
          <Container
            style={containerStyle}
            className="big-container"
          >
            <Navbar.Brand href="/" aria-label="daucodesigns">
              <LogoDaucodesigns 
                id="logo-daucodesigns"
              />
            </Navbar.Brand>
            {/* <div id="nav-items" style={{ display: "block" }}> */}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              <Nav>
                <div className="main-menu-link">
                  <Nav.Link
                    href="/quienes-somos/"
                    data-hover="quienes somos"
                    style={{
                      fontWeight:
                        path === "/quienes-somos/"
                          ? 600
                          : 400,
                    }}
                  >
                    quienes somos
                  </Nav.Link>
                </div>
                <div className="main-menu-link">
                  <Nav.Link
                    href="/nuestras-marcas/"
                    data-hover="nuestras marcas"
                    style={{
                      fontWeight:
                        path === "/nuestras-marcas/"
                          ? 600
                          : 400,
                    }}
                  >
                    nuestras marcas
                  </Nav.Link>
                </div>
                <div className="main-menu-link">
                  <Nav.Link
                    href="/proyectos/"
                    data-hover="proyectos"
                    style={{
                      fontWeight:
                        path === "/proyectos/" ? 600 : 400,
                    }}
                  >
                    proyectos
                  </Nav.Link>
                </div>
                <div className="main-menu-link">
                  <Nav.Link
                    href="/showrooms/"
                    data-hover="showrooms"
                    style={{
                      fontWeight:
                        path === "/showrooms/" ? 600 : 400,
                    }}
                  >
                    showrooms
                  </Nav.Link>
                </div>
                <div className="main-menu-link">
                  <Nav.Link
                    href="https://www.grasshc.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    data-hover="shop online"
                  >
                    shop online
                  </Nav.Link>
                </div>
              </Nav>
            </Navbar.Collapse>
            {/* </div> */}
          </Container>
        </Navbar>
      </div>
    </>
  )
}

export default MainNavbar
