import React, { useState, useEffect } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Link } from "gatsby"
import { Icon } from "react-icons-kit"
import { facebookOfficial } from "react-icons-kit/fa/facebookOfficial"
import { instagram } from "react-icons-kit/fa/instagram"
import { linkedinSquare } from "react-icons-kit/fa/linkedinSquare"

import SvgNetBistrot from "../svg/NetBistrot.svg"

function Footer() {
  const [acceptCookies, setAcceptCookies] = useState(true)

  useEffect(() => {
    const acptCookies = sessionStorage.getItem("cookiesConsent")
    if (!acptCookies) {
      setAcceptCookies(false)
    }
  }, [setAcceptCookies])

  const setCookiesConsent = () => {
    sessionStorage.setItem("cookiesConsent", true)
    setAcceptCookies(true)
  }

  return (
    <>
      {!acceptCookies ? (
        <Container
          className="info-cookies"
          style={{
            display: acceptCookies ? "none" : "block",
            maxWidth: "100%",
            zIndex: 100,
          }}
        >
          <Row style={{ maxWidth: "100%", margin: 0 }}>
            <Col sm={10} className="text-center">
                <p style={{ float: "left", margin:0, fontSize:"12px" }}>
                  Nuestro sitio utiliza cookies para mejorar su experiencia de
                  usuario. Hacer clic en cualquier enlace de esta página implica
                  su consentimiento para que se Instalen las cookies. Para
                  conocer más acerca de las políticas de privacidad visite&nbsp;
                  <a
                    href="/politica-de-privacidad/"
                    style={{ color: "#212529", fontWeight: 600 }}
                  >
                    Política de privacidad
                  </a>
                </p>
            </Col>
            <Col>
              <Button
                className="brand-button"
                onClick={evt => setCookiesConsent()}
                style={{ width: "auto", margin:0 }}
              >
                De acuerdo
              </Button>
            </Col>
          </Row>
        </Container>
      ) : (
        <></>
      )}
      <div className="footer-wrapper">
        <div className="height40" />
        <Container className="big-container">
          <Row>
            <Col lg={4}>
              <div className="text-center">
                <p>contacto</p>
                <p>info@daucodesigns.com</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="text-center">
                <p>tienda online</p>
                <Button
                  className="brand-button"
                  href="https://www.grasshc.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginBottom: "40px" }}
                >
                  grass hc
                </Button>
              </div>
            </Col>
            <Col lg={4}>
              <div className="text-center">
                <p>síguenos</p>
              </div>
              <Container>
                <Row>
                  <Col sm={2} />
                  <Col sm={8}>
                    <Container>
                      <Row className="text-center">
                        <Col xs={4}>
                          <Button
                            className="footer-button social-networks"
                            href="https://www.instagram.com/dauco.designs/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="instagram"
                          >
                            <Icon size={"38px"} icon={instagram} />
                          </Button>
                        </Col>
                        <Col xs={4}>
                          <Button
                            className="footer-button social-networks"
                            href="https://es-es.facebook.com/daucodesigns"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="facebook"
                          >
                            <Icon size={"38px"} icon={facebookOfficial} />
                          </Button>
                        </Col>
                        <Col xs={4}>
                          <Button
                            className="footer-button social-networks"
                            href="https://www.linkedin.com/company/daucodesigns/about/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="linkedin"
                          >
                            <Icon size={"38px"} icon={linkedinSquare} />
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Link to="/politica-de-privacidad/">
                <p style={{ fontSize: "14px", color: "#565656" }}>
                  política de privacidad
                </p>
              </Link>
            </Col>
          </Row>
        </Container>
        <div className="height40" />
        <div className="text-center footer-legal">
          <p>
            Dauco Designs, S.L. - Gregorio Benítez, 16 - bajos - 28043 - Madrid
            - CIF: B-63741680
          </p>
          <p>Copyright Dauco Designs, S.L. 2005 - {new Date().getFullYear()}</p>
        </div>
        <div className="text-center signature">
          <p>
            desarrollado&nbsp;por&nbsp;
            <a
              href="https://netbistrot.com"
              id="netbistrot"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="NetBistrot"
            >
              <SvgNetBistrot
                style={{ height: "13px", width: "85px", marginBottom: "4px" }}
              />
            </a>
          </p>
        </div>
        <div className="height40" />
      </div>
    </>
  )
}

export default Footer
